ul.list-view {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
}

ul.list-view li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  gap: 5px;
}

ul.list-view li:nth-child(odd) {
  background-color: var(--listRow);
}

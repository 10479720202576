#nav-bar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  background-color: #fbfbff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 5rem;
  box-shadow: var(--box-shadow);
  z-index: 100;
}
#nav-bar.active {
  width: 10rem;
}
#menu-btn-mobile {
  display: none !important;
}
#nav-bar .icon {
  width: var(--iconD);
  height: var(--iconD);
  filter: drop-shadow(0.8px 0.8px 0.8px #7d7d7d);
}
#nav-bar a {
  text-decoration: none;
}
#nav-bar li {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-link {
  text-decoration: none;
  border:none;
  background-color: transparent;
  color: var(--text);
  font-size: var(--fontM);
  cursor: pointer;
  padding: 0;
}
.menu-link:hover:not(:disabled) {
  color: var(--blue);
}
.menu-link:disabled {
  color: #7d7d7d
}
#nav-bar img.logo {
  width: 5rem;
  margin-bottom: 0.5rem;
  filter: none;
}
#nav-bar a:has(img.logo) {
  transition: none !important;
}
#nav-bar a:has(img.logo):hover {
  transform: none !important;
}
#nav-bar li.part-one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#nav-bar li.part-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}
#nav-bar .description {
  font-size: 12px;
  max-width: 4rem;
  margin-bottom: 0;
  color: var(--text);
  text-align: start;
  margin-left: 1rem;
}

#nav-bar li button,
#nav-bar a {
  border: none;
  display: flex;
  align-items: center;
  background: transparent;
  transition: all 0.2s linear;
}

#nav-bar li button:not(#menu-btn-mobile):hover,
#nav-bar a:not(.flash-items a):hover {
  transform: scale(1.3);
}

#nav-bar li.part-three {
  padding: 0rem 0rem 0.5rem 0rem;
}
.hamburger {
  cursor: pointer;
  width: 24px;
  height: 18px;
  transition: all 0.25s;
  position: relative;
}
#menu-btn-mobile.hamburger {
  width: 20px;
}
#menu-btn-mobile .hamburger-top,
#menu-btn-mobile .hamburger-middle,
#menu-btn-mobile .hamburger-bottom {
  width: 20px;
}
.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  width: 24px;
  height: 2px;
  top: 0;
  left: 0;
  background: #060f16;
  transform: rotate(0);
  transition: all 0.5s;
  filter: drop-shadow(1px 1px 1px #7d7d7d);
}

.hamburger-middle {
  transform: translateY(7px);
}
.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg) translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}
@media screen and (max-width: 1059px) {
  #nav-bar img.logo {
    width: 4rem;
  }
  #nav-bar.active {
    width: 8rem;
  }
  #nav-bar {
    width: 4rem;
  }
}
/* MOBILE CSS */
@media screen and (max-width: 950px) {
  #nav-bar li.part-two,
  #nav-bar li.part-three .desktop,
  #menu-btn {
    display: none !important;
    appearance: none;
    visibility: hidden;
  }
  #nav-bar li.part-three {
    padding: 0rem 1rem;
  }
  #menu-btn-mobile {
    display: flex !important;
  }
  #nav-bar li.part-one a img.logo {
    width: 3rem;
  }
  #nav-bar,
  #nav-bar.active {
    height: 3rem;
    width: 100%;
    flex-direction: row;
    position: fixed;
  }
  #nav-bar a:hover {
    transform: none;
  }
  #nav-bar img.logo {
    width: 4rem;
    margin-bottom: 0rem;
  }
}

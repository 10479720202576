div.exams-container img {
  width: 13rem;
}

div.exams-container {
  display: flex;
  position: relative;
  gap: 1rem;
  flex-direction: column;
}
div.exams-container div.logo-container img {
  background-color: #f4f4f4;
  width: 11rem;
  height: 6rem;
  object-fit: cover;
  border-radius: var(--borderRadius);
}
#loading {
  align-items: center;
}
div.exam-flex-box {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: white;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}

div.logo-container {
  width: 11rem;
  margin-right: 1rem;
}

div.middle-items h2 {
  font-size: var(--subHeadD);
  text-transform: capitalize;
}
div.middle-items p {
  font-size: var(--paraD);
  color: var(--subtitleColor);
}
div.around-no-exams {
  flex: 1 auto;
  margin: 1rem 0rem;
}

div.exam-box:has(div.around-no-exams) {
  height: -webkit-fill-available;
}

div#no-exams,
div#loading,
div#flash-loading {
  text-align: center;
  padding: 5rem;
}
/* MINIMIZED WINDOW STILL DESKTOP VIEW CSS */
/* ---------------------------------------------- */

@media screen and (max-width: 635px) {
  div.middle-items h2 {
    font-size: var(--subHeadM);
  }
}
@media screen and (max-width: 500px) {
  div.exam-flex-box {
    flex-direction: column;
    padding: 0;
  }
  div.exam-flex-box div.middle-items,
  a.exam-flex-box.no-decor-link,
  div.logo-container,
  div.exams-container div.logo-container img {
    width: 100%;
  }
  div.exam-flex-box div.middle-items {
    padding: 1rem;
  }
  div.logo-container {
    margin-right: 0;
  }
  div.exams-container div.logo-container img {
    height: 11rem;
    border-radius: var(--borderRadius) var(--borderRadius) 0 0;
  }
}

#profile-modal,
#upload-modal,
#delete-exam-modal,
#exam-id-modal,
#exam-modal,
#resource-modal,
#choice-modal,
#add-modal,
#delete-modal,
#card-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 200ms ease-in-out;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  background-color: white;
  min-width: fit-content;
  max-width: 100%;
  color: #060f16;
  z-index: 10000001;
  border: none;
}

#choice-modal,
#card-modal {
  max-width: 600px;
  width: 100%;
}

#add-modal {
  max-width: 350px;
  width: 100%;
}
:is(#profile-modal, #upload-modal, #delete-exam-modal, #exam-id-modal, #exam-modal, #resource-modal, #choice-modal, #add-modal, #delete-modal, #card-modal).active {
  transform: translate(-50%, -50%) scale(1);
}

#profile-overlay,
#upload-overlay,
#delete-exam-overlay,
#exam-id-overlay,
#exam-overlay,
#resource-overlay,
#choice-overlay,
#add-overlay,
#delete-overlay,
#card-overlay {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 200ms ease-in-out;
  background-color: #060f168a;
  backdrop-filter: blur(10px);
  pointer-events: none;
  z-index: 10000000;
}

:is(#profile-overlay, #upload-overlay, #delete-exam-overlay, #exam-id-overlay, #exam-overlay, #resource-overlay, #choice-overlay, #add-overlay, #delete-overlay, #card-overlay).active {
  pointer-events: all;
  opacity: 1;
}

div.header h1 {
  font-size: var(--headingD);
  margin-bottom: 0;
}
div.header {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--borderColor);
}

.close-modal {
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  color: #060f16;
  font-size: 2rem !important;
  transition: all 0.2s linear;
  width: auto;
  height: 2rem;
  display: flex;
  align-items: center;
}
.close-modal:not(#back-button):hover {
  transform: scale(1.3) rotate(180deg);
}
#choice-modal .close-modal#back-button:hover {
  transform: scale(1.3);
}

#card-modal div.modal-body div.top-items {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.question-input textarea {
  overflow: hidden;
}
#profile-modal div.modal-body > div,
:is(#upload-modal, #delete-exam-modal, #choice-modal) div.modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  column-gap: 1rem;
  align-items: center;
  align-items: flex-end;
}
#upload-modal div.modal-body {
  align-items: stretch;
  flex-direction: column;
}
#resource-modal div.modal-body {
  display: flex;
  gap: 1rem;
  align-items: stretch;
}
#upload-modal div.modal-body .alert-box,
#upload-modal div.modal-body .error {
  width: 100% !important;
}
:is(#delete-exam-modal, #exam-id-modal, #exam-modal, #choice-modal, #delete-modal, #card-modal, #add-modal)
  div.modal-body {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  align-items: stretch;
}
#delete-exam-modal div.modal-body div.option-buttons,
#delete-modal div.modal-body div.option-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
#delete-exam-modal
  > div.modal-body
  > div.option-buttons
  button:not(button.close-modal),
#delete-modal
  > div.modal-body
  > div.option-buttons
  button:not(button.close-modal) {
  margin: 1rem 1rem 0rem 1rem;
}

:is(#profile-modal, #upload-modal, #delete-exam-modal, #exam-modal, #add-modal, #delete-modal, #card-modal)
  button.modal-save,
#delete-exam-modal button.cancel,
#delete-modal button.cancel {
  width: 15rem;
  color: #fbfbff;
  border-radius: var(--borderRadius);
  height: 3.5rem;
  background-color: #b80c09;
  box-shadow: var(--box-shadow);
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
}
#card-modal button.modal-save {
  min-width: 17rem;
  width: 100%;
}
#exam-modal button.modal-save {
  background-color: #0b4f6c;
}
:is(#exam-modal, #add-modal) button.modal-save {
  width: 30rem;
}
#delete-exam-modal button.cancel,
#delete-modal button.cancel {
  background-color: #0b4f6c;
}

#delete-exam-modal button.cancel:hover,
#delete-modal button.cancel:hover,
#exam-modal button.modal-save:hover {
  transform: none;
  background-color: #072a39;
}

button b {
  font-size: small;
}
button.modal-save:hover {
  background-color: #6a0807;
  transform: none;
}

#resource-modal div.modal-body {
  padding: 1rem;
}

table {
  margin-bottom: 0rem;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  padding: 1rem;
}
thead tr {
  background-color: #fbfbff;
  position: sticky;
  top: -1px;
  z-index: 1;
}
tr:nth-child(odd):not(thead tr) {
  background-color: #e8e8e8;
}
table td.td-question {
  display: block;
  width: 42.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.modal-body .container-items {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  gap: 1rem;
}
#choice-modal .choices {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding-left: 0.5rem;
}
p.match-error {
  width: auto !important;
  text-align: center;
  padding-bottom: 0rem;
  text-transform: uppercase;
  color: #060f16;
  font-size: small !important;
}
div.table-wrapper:has(table > tbody > tr:nth-child(7)) {
  height: 19rem;
  overflow-y: scroll;
}
div.table-wrapper {
  overflow-x: hidden;
  width: 100%;
  border: none;
}
div.table-wrapper tr td button {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  color: #fbfbff;
  align-items: center;
  border: none;
  transition: all 0.2s linear;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}
div.table-wrapper tr td:has(button) {
  padding: 0.6rem 0.5rem;
}
div.table-wrapper tr td:nth-child(3) button {
  background-color: #01baef;
}
div.table-wrapper tr td:nth-child(4) button {
  background-color: #0c0f16;
}
div.table-wrapper tr td button:hover {
  transform: scale(1.2);
}
.modal-save:disabled {
  opacity: 0.5 !important;
}

@media screen and (max-width: 662px) {
  #save-button {
    width: 100% !important;
  }
}
@media screen and (max-width: 645px) {
  #choice-modal,
  #card-modal {
    max-width: 90%;
  }
}
@media screen and (max-width: 622px) {
  #delete-modal div.modal-body {
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  div.option-buttons {
    flex-direction: column;
  }
}
@media screen and (max-width: 575px) {
  :is(#exam-modal, #add-modal) button.modal-save {
    width: 20rem;
  }
}
@media screen and (max-width: 543px) {
  #resource-modal div.modal-body {
    flex-direction: column;
  }
  #resource-modal {
    width: 18rem;
  }
}

@media screen and (max-width: 400px) {
  :is(#exam-modal, #add-modal) button.modal-save {
    width: 15rem;
  }
  #add-modal {
    max-width: 90%;
  }
}
@media screen and (max-width: 450px) {
  div.header {
    padding: 10px;
  }
  div.header h1 {
    font-size: var(--headingM);
  }
}
@media screen and (max-height: 480px) and (orientation: landscape) {
  #card-modal.active {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  #card-modal div.modal-body > div {
    width: 100%;
  }
}

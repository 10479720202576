.form__group {
  position: relative;
  padding: 9px 0 0;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 1px solid var(--borderColor);
  border-radius: var(--borderRadius);
  outline: 0;
  font-size: 16px;
  color: var(--text);
  padding: 10px;
  background: transparent;
  transition: border-color 0.2s;
  resize: none;
}
.form__field.error {
  border: 1px solid var(--red);
}
.form__field.error:focus {
  border: 2px solid var(--red);
}
.form__group:has(.form__field.error:focus) .form__label,
.form__field.error::placeholder {
  color: var(--red);
}
.form__label,
.form__field:focus ~ .form__label {
  position: absolute;
  display: none;
  top: 0px;
  padding: 0px 6px;
  left: 9px;
  transition: 0.2s;
  font-size: 12px;
  background-color: white;
  color: var(--text);
}

.form__field:focus ~ .form__label {
  color: var(--text);
}

.form__field:focus {
  border: 2px solid var(--text);
}
.form__group:has(.form__field:focus, .form__field:not(:placeholder-shown))
  .form__label {
  display: block;
}

.category-decks {
  row-gap: 4rem;
  column-gap: 3rem;
  margin: 0;
  padding: 3rem 1.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  justify-items: center;
}

div#no-exams,
div#flash-loading {
  background-color: transparent !important;
  box-shadow: none !important;
  text-align: center;
  padding: 5rem;
}
div#flash-loading {
  align-items: center;
}
#no-items {
  background-color: white;
  border-radius: var(--borderRadius);
  padding: 0.5rem 1rem;
}
.category-decks:has(#no-items) {
  margin: 0rem 1.5rem;
  height: 8rem;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.deck-card {
  background-color: white;
  padding: 1rem;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
  border: none;
  height: 12.3rem;
  width: 360px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.deck-card .more {
  position: absolute;
  top: -0.7rem;
  right: 0.5rem;
}
.deck-card::before,
.deck-card::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: inherit;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}

.deck-card::before {
  transform: rotate(5deg); /* Adjust the rotation angle as needed */
  z-index: -1; /* Set the z-index to 0 for the card with a positive angle */
}

.deck-card::after {
  transform: rotate(-5deg); /* Adjust the rotation angle as needed */
  z-index: -1; /* Set the z-index to 0 for the card with a negative angle */
}
@media screen and (max-width: 476px) {
  .category-decks {
    padding: 1rem 0.5rem;
    grid-template-columns: repeat(auto-fill, 300px);
  }
  .deck-card,
  .deck-card-container {
    width: 300px;
    height: 10rem;
  }
}

@media screen and (max-width: 365px) {
  .category-decks {
    grid-template-columns: repeat(auto-fill, 250px);
  }
  .deck-card,
  .deck-card-container {
    width: 250px;
    height: 9rem;
  }
}

@media screen and (max-width: 323px) {
  .deck-card {
    width: 100%;
  }
  .category-decks {
    flex-direction: column;
    align-items: stretch;
  }
}

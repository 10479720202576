div.search-bar button,
div.search input {
  border: none;
  padding: 0.8rem;
  height: 3rem;
}
div.search input {
  border-radius: var(--borderRadius);
  border: none;
  width: 40rem;
}

div.search input:focus {
  outline: none;
}
div.search {
  background-color: white;
  border-radius: var(--borderRadius);
  box-shadow: var(--box-shadow);
}
div.search-bar {
  display: flex;
  border-radius: var(--borderRadius);
  border: none;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

div.around-search-bar button {
  font-size: 30px;
  color: #7d7d7d;
}
div.around-search-bar button:hover {
  background-color: transparent;
  color: #000000;
}
div.search-bar button,
div.search {
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
}
div.search {
  justify-content: space-between;
}
@media screen and (max-width: 1095px) {
  div.filter-container div.search-bar {
    flex: 1 1 auto;
  }
  div.filter-container form,
  div.filter-container div.search,
  div.filter-container div.search input {
    width: 100% !important;
  }
  .filter-container {
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 950px) {
  div.search-bar#exams-search,
  #exams-search div.search {
    width: 100%;
  }
}
